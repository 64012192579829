
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.lookups-list {
    position: relative;

    .title {
        line-height: normal;
        margin-bottom: 1.5rem;
        @include handleAllBreakpoints(
            "font-size",
            "titleFontSize",
            "24px::var(--global-sections-typography-title-size)"
        );
        @include handleAllBreakpoints("color", "titleFontColor", "#424242");
        @include handleAllBreakpoints("font-weight", "titleFontWeight", "500");
        @include handleAllBreakpoints(
            "text-align",
            "titleAlign",
            "var(--global-sections-typography-title-align)"
        );
        @include handleAllBreakpoints("margin", "titleMargin", "0 0 3.5rem 0");
    }

    .items-grid {
        @include handleAllBreakpoints("gap", "gap", "2rem");
        place-items: center;

        display: grid;
        grid-template-columns: repeat(var(--grid-cols-sm), 1fr);
        @include md {
            grid-template-columns: repeat(var(--grid-cols-md), 1fr);
        }
        @include lg {
            grid-template-columns: repeat(var(--grid-cols-lg), 1fr);
        }
        @include xl {
            grid-template-columns: repeat(var(--grid-cols-xl), 1fr);
        }
    }

    .item {
        a {
            text-align: center;
            display: block;
            border-bottom: 2px solid transparent;

            @include handleAllBreakpoints("font-size", "itemFontSize", "18px");
            @include handleAllBreakpoints("color", "itemFontColor", "#3f7fea");
            @include handleAllBreakpoints(
                "font-weight",
                "itemFontWeight",
                "normal"
            );
            @include handleAllBreakpoints(
                "background-color",
                "itemBackgroundColor"
            );
            @include handleAllBreakpoints("padding", "itemPadding");
            @include handleAllBreakpoints("border-radius", "itemBorderRadius");
            @include handleAllBreakpoints("box-shadow", "itemBoxShadow");
        }
        &:hover {
            a {
                @include handleAllBreakpoints(
                    "background-color",
                    "itemHoverBackgroundColor"
                );
                @include handleAllBreakpoints(
                    "border-bottom",
                    "itemFontColor",
                    "2px solid #3f7fea"
                );
                @include handleAllBreakpoints(
                    "box-shadow",
                    "itemHoverBoxShadow"
                );
            }
        }
    }
    .filter {
        filter: grayscale(100%) contrast(30%) opacity(0.5);
        transition: filter 0.3s linear;
        &:hover {
            filter: none;
        }
    }
}
